<template>
    <!-- 회사소개 START -->
	<div class="content_container company_introduction">
		<div class="content">
			<div class="banner">
				<h1>회사개요</h1>
			</div>
			<section class="company_overview">
				<h3>기업개요</h3>
				<div class="flex_container">
					<div class="flex_item">
						<img src="@/assets/images/sub/company/mpcbuilding_img.png" alt="무궁화캐피탈 빌딩 전경">
					</div>
					<div class="flex_item">
						<div class="textBox">
							<p style="font-size: var(--font-size-xx-large)"><strong>M</strong>ake a <strong>P</strong>artner for your <strong>C</strong>hallenges</p>
							<p>여신전문 종합금융회사 <strong>무궁화캐피탈</strong></p>
							<p>무궁화캐피탈은 무궁화금융그룹의 관계회사로서 부동산 금융의 전문성을 갖춘 여신전문 금융회사입니다.<br />
							관계사와의 시너지 창출을 통하여 부동산 금융 IB 및 자금중개, 주선업무를 성장동력으로 부동산 금융시장을 선도하는 우량 여신전문 회사로 도약하고자 하며, 투자금융, 신기술사업금융 시장에 진출하여 여신전문 종합금융회사로 성장하고자 합니다.</p>
						</div>
					</div>
				</div>
			</section>
			<section class="company_identity">
				<h3>경영 이념</h3>
				<div class="management">
					<div class="flex_container">
						<div class="flex_item">
							<h4>경영 이념</h4>
							<p>새로운 미션과 비전을 <br />통해 고객존중, 신뢰정직, <br />가치창출, 동반성장을 이룩하고, <br />부동산 금융시장을 선도하는 <br />우량 여신전문 회사로 <br />도약하고자 합니다.</p>
						</div>
						<div class="flex_item">
							<div class="mission">
								<p>MISSION</p>
								<div>
									<p><span>mission icon</span></p>
									<p>부동산 금융시장을 선도하여 고객의 가치를
										창조하는 여신전문 회사</p>
								</div>
							</div>
							<div class="vision">
								<p>VISION</p>
								<div>
									<p><span>vision icon</span></p>
									<p>고객의 가치창출을 통해 수익성, 성장성의 획기적인 실적 거양으로 우량 여신전문 회사로 도약</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="core_value">
					<div class="flex_container">
						<div class="flex_item">
							<h4>핵심가치</h4>
							<p>미션과 비전을 달성하기 <br />위해 구성원 모두 공유하고 <br />인식하고 있다면, 그것이 <br />좋은 가치입니다.</p>
						</div>
						<div class="flex_item">
							<div class="core_value_info_gp">
								<img src="@/assets/images/sub/company/core_value_infogp.png" alt="무궁화 캐피탈의 핵심가치는 고객존중, 신뢰정직, 가치창출, 혁신주도, 동반성장 입니다">
								<div class="m_cv_text">
									<p>고객<br />존중</p>
									<p>신뢰<br />정직</p>
									<p>가치<br />창출</p>
									<p>혁신<br />주도</p>
									<p>동반<br />성장</p>
								</div>
							</div>
							<div class="core_value_icon">
								<div class="client_first">
									<p><img src="@/assets/images/sub/company/icon_management01.svg" alt="client_first"></p>
									<p>고객존중</p>
									<p>고객의 입장과 이익을 최우선으로 판단하고 의사결정</p>
								</div>
								<div class="trust">
									<p><img src="@/assets/images/sub/company/icon_management02.svg" alt="trust"></p>
									<p>신뢰정직</p>
									<p>금융회사 임직원으로서 윤리 의식, 신의성실의 자세</p>
								</div>
								<div class="value">
									<p><img src="@/assets/images/sub/company/icon_management03.svg" alt="value"></p>
									<p>가치창출</p>
									<p>고객의 성장과 회사의 성장을 함께 도모하는 가치창출</p>
								</div>
								<div class="leader">
									<p><img src="@/assets/images/sub/company/icon_management04.svg" alt="leader"></p>
									<p>혁신주도</p>
									<p>창의적인 사고와 과감한 도전으로 변화와 혁신을 주도</p>
								</div>
								<div class="growth">
									<p><img src="@/assets/images/sub/company/icon_management05.svg" alt="growth"></p>
									<p>동반성장</p>
									<p>고객, 직원, 조직 전체의 발전을 넘어 사회 발전에 기여</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="gray_wrapper ci_wrap">
				<section class="gray ci">
					<h3>CI소개</h3>
					<div class="signature">
						<p class="si_sub_title">Signature</p>
						<p class="si_contents">상호명과 동일한 무궁화의 이미지를 안정감과 신뢰감을 줄 수 있도록 디자인하여 캐피탈회사의 느낌을 표현하였습니다.<br />또 Gold color와 Blue color를 사용함으로써 세계화, 미래화를 열어가는 무궁화 캐피탈의 비전을 상징화하였습니다.</p>
						<div class="flex_container">
							<div class="flex_item">
								<img src="@/assets/images/common/ci_color.svg" alt="무궁화 ci 혼합형">
							</div>
						</div>
					</div>
					<div class="color_system">
						<p class="col_sub_title">Color System</p>
						<p class="col_contents">네이비와 골드를 메인 컬러와 서브 컬러로 사용하여 안정감과 신뢰감을 나타냅니다.</p>
						<div class="flex_container">
							<div class="flex_item col_box01">
								<p class="col_name">무궁화 NAVY</p>
								<p>R:0  G:45  B:122</p>
								<p>C:99  M:96  Y:57  K:36</p>
							</div>
							<div class="flex_item col_box02">
								<p class="col_name">무궁화 GOLD</p>
								<p>R:197  G:153  B:0</p>
								<p>C:27  M:44  Y:100  K:0</p>
							</div>
							<div class="flex_item col_box03">
								<p class="col_name">무궁화 YELLOW</p>
								<p>R:255  G:229  B:63</p>
								<p>C:6  M:11  Y:78  K:0</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
    <!-- 회사소개 END -->
</template>

<script>
export default {
    name : "CompanyIntroduction",
}
</script>

